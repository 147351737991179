import React from 'react';
import { ComposableMap, Geographies, Geography, Graticule, Sphere } from 'react-simple-maps';
import timezoneData from './timezone.geojson'; // Adjust the path to your GeoJSON file
import continentsData from './continents.geojson'; // Add your continents GeoJSON file

function TimezoneMap({ highlightedTimezones, defaultColor = '#C0E8F9' }) {
  return (
    <div style={{ backgroundColor: '#87CEEB', padding: '20px', pointerEvents: 'none'  }}> {/* Ocean color */}
      <ComposableMap>
        <Sphere stroke="#000" strokeWidth={0.5} fill="#ADD8E6" />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        
        {/* Continent Layer */}
        <Geographies geography={continentsData}>
          {({ geographies }) =>
            geographies.map((geo, i) => (
              <Geography
                key={`continent-${i}`} // Use a unique key for continents
                geography={geo}
                fill="#A9A9A9" // Color for continents
                stroke="#D6D6DA"
                onClick={(e) => e.preventDefault()} // Prevent click actions
              />
            ))
          }
        </Geographies>
        
        {/* Timezone Layer */}
        <Geographies geography={timezoneData}>
          {({ geographies }) =>
            geographies.map((geo, i) => {
              const timezoneIdentifier = geo.properties.tz_name1st; // Using the tz_name1st property
              const isHighlighted = highlightedTimezones.includes(timezoneIdentifier);
            //   console.log(`Geography ${timezoneIdentifier} is ${isHighlighted ? '' : 'not '}highlighted`);
            //   console.log(`Highlighted Timezones: ${highlightedTimezones}`);

              return (
                <Geography
                  key={`timezone-${i}`} // Use a unique key for timezones
                  geography={geo}
                  fill={isHighlighted ? '#FD8D3C' : defaultColor} // Highlight timezones with a specific color
                  stroke="#FFF"
                  strokeWidth={0.5}
                  style={{
                    default: { opacity: 0.7 },
                    hover: { opacity: 1.0 },
                    pressed: { opacity: 1.0 },
                  }}
                  onClick={(e) => e.preventDefault()} // Prevent click actions
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}

export default TimezoneMap;
