import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import './App.css';
import TimezoneMap from './TimezoneMap';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

function Home() {
  const [inputTime, setInputTime] = useState('');
  const [serverTime, setServerTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [serverTimezone, setServerTimezone] = useState(moment.tz.guess());  // Guessing or set to your server timezone
  const [matchingTimezones, setMatchingTimezones] = useState([]);
  const [highlightedTimezones, setHighlightedTimezones] = useState([]);
  const [fact, setFact] = useState('');
  const factRef = useRef(null);

  usePageTracking();

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateServerTime();
    }, 1000);  // Update time every second

    return () => clearInterval(intervalId);  // Clear interval on component unmount
  }, []);

  useEffect(() => {
    if (fact) {
      factRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [fact]);

  const updateServerTime = () => {
    setServerTime(moment().format('YYYY-MM-DD HH:mm:ss'));
  };

  const handleTimeChange = (event) => {
    setInputTime(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    findMatchingTimezones();
  };

  const findMatchingTimezones = () => {
    const allTimezones = moment.tz.names();
    const serverMoment = moment.tz(serverTime, serverTimezone);
    const potentialMatches = allTimezones.filter(timezone => {
      const timeInZone = serverMoment.clone().tz(timezone).format('HH:mm');
      return timeInZone === inputTime;
    });
    setMatchingTimezones(potentialMatches);

    console.log("Potential matches: ", potentialMatches);

    // Highlight all matching timezones
    setHighlightedTimezones(potentialMatches);

    // Fetch an interesting fact about a random matching timezone
    if (potentialMatches.length > 0) {
      const randomTimezone = potentialMatches[Math.floor(Math.random() * potentialMatches.length)];
      fetchFunFact(randomTimezone);
    } else {
      setFact('');
    }
  };

  const fetchFunFact = async (timezone) => {
    try {
      const response = await axios.post('https://api.openai.com/v1/completions', {
        model: 'gpt-3.5-turbo-instruct',
        prompt: `Tell me an interesting fact about the timezone: ${timezone}`,
        max_tokens: 100,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`
        }
      });
      setFact(response.data.choices[0].text.trim());
    } catch (error) {
      console.error("Error fetching interesting fact:", error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Timezone Lookup by Input Time</h1>
        <p>Server Time: {serverTime} ({serverTimezone})</p>
      </header>
      <main className="App-content">
        <div className="App-form">
          <form onSubmit={handleSubmit}>
            <label>
              Enter Time (HH:mm):
              <input
                type="text"
                value={inputTime}
                onChange={handleTimeChange}
                placeholder="HH:mm"
              />
            </label>
            <button type="submit">Determine Timezone</button>
          </form>
        </div>
        <div className="App-map-container">
          <div className="App-map">
            <TimezoneMap highlightedTimezones={highlightedTimezones} />
          </div>
          <div className="App-timezones">
            {matchingTimezones.length > 0 && (
              <div>
                <h2>Matching Timezones</h2>
                <ul>
                  {matchingTimezones.map(timezone => (
                    <li key={timezone}>{timezone}</li>
                  ))}
                </ul>
                {fact && (
                  <div className="App-fact" ref={factRef}>
                    <h3>Interesting Fact</h3>
                    <p>{fact}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} Timezone Lookup. All rights reserved.</p>
        <p>
          Have feedback? <a href="mailto:timezonelookup.feedback@gmail.com">Send us an email</a>
        </p>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Add more routes here if needed */}
      </Routes>
    </Router>
  );
}

export default App;
